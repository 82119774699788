import {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler
} from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSettings, PasswordField } from '@aws-amplify/ui-react';
import { Box, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import './ChangePassword.css';

interface ChangePasswordProps {
  handleClose: () => void;
}

export const ChangePassword = ({ handleClose }: ChangePasswordProps) => {
  const { t } = useTranslation();
  const isMobile = useAppSelector(selectIsMobile);

  const handleSuccess = () => {
    toast.success(t('profile.passwordSuccess'));
    handleClose();
  };

  const getSubmitButton = (onClick: MouseEventHandler<HTMLButtonElement> | undefined, isDisabled: boolean | undefined) => (
    <Box className="submit-button-container">
      <Button className="update-password-button" type="submit" onClick={onClick} disabled={isDisabled}>
        {t('button.save')}
      </Button>
      {!isMobile && (
        <Button className="update-password-button" type="button" onClick={handleClose}>
          {t('button.cancel')}
        </Button>
      )}
    </Box>
  );

  const getFieldComponent = (
    fieldValidationErrors: string[] | undefined,
    name: string,
    label: string,
    onBlur: FocusEventHandler<HTMLInputElement>,
    onChange: ChangeEventHandler<HTMLInputElement>
  ) => (
    <Box>
      <PasswordField
        className="passwordfield"
        name={name}
        label={t(`profile.${label}`)}
        onBlur={onBlur}
        onChange={onChange}
        style={{ outline: 'none' }}
      />
      {fieldValidationErrors?.map(error => (
        <Typography key={error} role="alert" variant="body2">{error}</Typography>
      ))}
    </Box>
  );

  return (
    <Box className="passwordfield-container">
      <AccountSettings.ChangePassword
        onSuccess={handleSuccess}
        components={{
          CurrentPasswordField: ({
            fieldValidationErrors, name, onBlur, onChange
          }) => getFieldComponent(fieldValidationErrors, name, 'currentPassword', onBlur, onChange),
          NewPasswordField: ({
            fieldValidationErrors, name, onBlur, onChange
          }) => getFieldComponent(fieldValidationErrors, name, 'newPassword', onBlur, onChange),
          ConfirmPasswordField: ({
            fieldValidationErrors, name, onBlur, onChange
          }) => getFieldComponent(fieldValidationErrors, name, 'confirmPassword', onBlur, onChange),
          SubmitButton: ({ onClick, isDisabled }) => getSubmitButton(onClick, isDisabled)
        }}
      />
    </Box>
  );
};
