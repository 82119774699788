import {
  ChangeEvent, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Typography, Checkbox, FormControlLabel
} from '@mui/material';
import dayjs from 'dayjs';
import {
  ChangePassword,
  Loading,
  ModalContainer,
  PrimaryContent,
  SecondaryContent
} from '../../components';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectAccount, selectIsMobile } from '../../app/appSlice';
import './ProfilePage.css';
import { selectSelectedProperty } from '../property/propertySlice';
import listS3Objects from '../../aws/api/listS3Objects';
import deleteFromS3 from '../../aws/api/deleteFromS3';
import { getBackgroundUrl } from '../../utils';
import putObject from '../../aws/api/putObject';
import defaultBackground from '../../assets/images/christian-mackie-SxBca4GcC9k-unsplash.jpg';
import {
  selectSubscription, selectLoading, getSubscription, cancelSubscription, changeSubChargeNotify
} from './profileSlice';

export const ProfilePage = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [backgroundName, setBackgroundName] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const loadingSlice = useAppSelector(selectLoading);
  const subscription = useAppSelector(selectSubscription);

  const isMobile = useAppSelector(selectIsMobile);
  const account = useAppSelector(selectAccount);
  const selectedProperty = useAppSelector(selectSelectedProperty);

  const dispatch = useAppDispatch();

  const fetchBackgroundName = async () => {
    const objs = await listS3Objects(`${selectedProperty?.propertyId}/background`);
    if (objs?.length) {
      setBackgroundName(objs[0]?.split('/')?.at(-1));
    } else {
      setBackgroundName('');
    }
  };

  useEffect(() => {
    fetchBackgroundName();
    dispatch(getSubscription());
  }, []);

  const handleSelectImage = async (images: FileList | null) => {
    if (images?.length && selectedProperty?.propertyId) {
      setLoading(true);
      await deleteFromS3(`${selectedProperty?.propertyId}/background`);
      await putObject(`${selectedProperty?.propertyId}/background/${images[0].name}`, images[0]);
      const url = await getBackgroundUrl(selectedProperty?.propertyId);
      document.documentElement.style.setProperty('--background-image', `url(${url || defaultBackground})`);
      await fetchBackgroundName();
      setLoading(false);
    }
  };

  const restoreDefaultBg = async () => {
    if (selectedProperty?.propertyId) {
      await deleteFromS3(`${selectedProperty?.propertyId}/background`);
      document.documentElement.style.setProperty('--background-image', `url(${defaultBackground})`);
      setBackgroundName('');
    }
  };

  const handleCancelSubscription = async () => {
    await dispatch(cancelSubscription());
    await dispatch(getSubscription());
  };

  const handleSubChargeNotify = async (event: { target: { checked: any; }; }) => {
    await dispatch(changeSubChargeNotify({
      notify: event.target.checked
    }));
    await dispatch(getSubscription());
  };

  if (loading || loadingSlice) {
    return (
      <PrimaryContent title={t('common.profile')}>
        <Loading />
      </PrimaryContent>
    );
  }

  return (
    <>
      <PrimaryContent title={t('common.profile')}>
        <Box className="profile-container">
          <Box>
            <Box className="profile-content-container">
              <Typography component="h3" variant="h6">{t('profile.loginInfo')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Box>
                  {account?.name && <Typography>{`${t('profile.userName')}: ${account.name}`}</Typography>}
                  <Typography>{`${t('users.email')}: ${account?.email}`}</Typography>
                  <Button className="basic-button" onClick={() => setChangePassword(!changePassword)}>
                    {t('profile.changePassword')}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="profile-content-container">
              <Typography component="h3" variant="h6">{t('profile.paymentInfo')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Box>
                  <Typography>{`${t('profile.subscriptionValid')}: ${dayjs(subscription.valid).format('D.M.YYYY')}`}</Typography>
                  {subscription.active ? (
                    <FormControlLabel
                      className="form-control-label"
                      label={t('profile.chargeNotify')}
                      labelPlacement="end"
                      control={
                        <Checkbox onChange={handleSubChargeNotify} checked={subscription.notify} />
                      }
                    />
                  ) : (
                    ''
                  )}
                  <Typography>
                    {subscription.active ? (
                      <Button className="basic-button" onClick={() => handleCancelSubscription()}>
                        {t('profile.cancelSubscription')}
                      </Button>
                    ) : (
                      `${t('profile.subscriptionCancelled')}`
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="profile-content-container">
              <Typography component="h3" variant="h6">{t('common.background')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Box>
                  {backgroundName ? (
                    <Typography>{backgroundName}</Typography>
                  ) : (
                    <Typography fontStyle="italic">{t('common.defaultBackground')}</Typography>
                  )}
                  <Button className="basic-button" onClick={() => inputRef?.current?.click()}>
                    {t('menu.changeBackground')}
                  </Button>
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectImage(event.target.files)}
                    hidden
                  />
                  <Button className="basic-button" onClick={restoreDefaultBg}>
                    {t('common.restoreDefault')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PrimaryContent>
      {isMobile && (
        <ModalContainer
          title={t('profile.changePassword')}
          open={changePassword}
          handleClose={() => setChangePassword(false)}
        >
          <Box>
            <ChangePassword handleClose={() => setChangePassword(false)} />
          </Box>
        </ModalContainer>
      )}
      {!isMobile && changePassword && (
        <SecondaryContent title={t('profile.changePassword')}>
          <ChangePassword handleClose={() => setChangePassword(false)} />
        </SecondaryContent>
      )}
    </>
  );
};
