import { useEffect, useState } from 'react';
import {
  useNavigate,
  Outlet,
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Loading, PageSkeleton } from '../components';
import {
  BrochurePage,
  ConstructionPage,
  PropertyPage,
  EquipmentPage,
  DocumentPage,
  LoginPage,
  MaintenancePage,
  OrderPage,
  ProfilePage,
  PropertySetupPage,
  RenovationPage,
  UsersPage
} from '../pages';
import awsExports from '../aws-exports';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  selectAccount,
  updateAccount,
  updateIsMobile
} from './appSlice';
import {
  fetchProperties, selectProperties, selectPropertiesLoading, selectSelectedProperty
} from '../pages/property/propertySlice';
import { Account } from '../models/common';
import { fi } from '../locales/fi/amplifyAuthTranslation';
import theme from '../styles/theme';
import '../styles/common.css';
import './App.css';
import { fetchDocuments } from '../pages/document/documentSlice';
import defaultBackground from '../assets/images/christian-mackie-SxBca4GcC9k-unsplash.jpg';
import {
  fetchMaintenances, selectMaintenances, selectReminded, updateRemindedStatus
} from '../pages/maintenance/maintenanceSlice';
import { dateToTableFormat, getBackgroundUrl } from '../utils';
import { PermissionPage } from '../pages/permission/PermissionPage';
import { fetchLink } from '../pages/permission/permissionSlice';
import { LinkUserPage } from '../pages/linkUser/LinkUserPage';

Amplify.configure(awsExports);

const App = () => {
  const { t } = useTranslation();
  const { route } = useAuthenticator(context => [context.authStatus]);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const properties = useAppSelector(selectProperties);
  const propertiesLoading = useAppSelector(selectPropertiesLoading);
  const account = useAppSelector(selectAccount);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const maintenances = useAppSelector(selectMaintenances);
  const reminded = useAppSelector(selectReminded);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isAdmin = account?.groups?.some(group => group === 'admin');
  const isLinkURL = location.pathname.includes('linkki');

  useEffect(() => {
    dispatch(updateIsMobile(window.innerWidth < 1080));
    I18n.setLanguage('fi');
    I18n.putVocabularies({ fi });
  }, []);

  useEffect(() => {
    if (isLinkURL) {
      const linkId = searchParams.get('linkId');
      if (linkId) {
        dispatch(fetchLink(linkId));
      }
    }
  }, []);

  useEffect(() => {
    if (route === 'authenticated') {
      dispatch(fetchProperties());
    }
  }, [route]);

  useEffect(() => {
    if (route === 'authenticated' && selectedProperty?.propertyId) {
      dispatch(fetchDocuments(selectedProperty.propertyId));
      dispatch(fetchMaintenances(selectedProperty.propertyId));
    }
  }, [route, selectedProperty]);

  useEffect(() => {
    if (route === 'authenticated') {
      if (properties?.length === 0) {
        navigate('/kiinteistö/aloitus');
      }
      setIsLoggedIn(true);
      return;
    }
    setIsLoggedIn(false);
  }, [route, properties]);

  useEffect(() => {
    const fetchBackgroundUrl = async () => {
      let url;
      if (selectedProperty?.propertyId) {
        url = await getBackgroundUrl(selectedProperty?.propertyId);
      }
      document.documentElement.style.setProperty('--background-image', `url(${url || defaultBackground})`);
    };

    if (route === 'authenticated') {
      fetchBackgroundUrl();
    } else if (route !== 'idle') {
      document.documentElement.style.setProperty('--background-image', `url(${defaultBackground})`);
    }
  }, [route, selectedProperty]);

  useEffect(() => {
    // Notify the user of upcoming maintenance. Quick implementation.
    maintenances?.forEach(maintenance => {
      const oneWeekForward = new Date();
      oneWeekForward.setDate(oneWeekForward.getDate() + 1 * 7);
      if (oneWeekForward > new Date(maintenance.nextMaintenance) && !reminded) {
        toast.info(`
          ${t('renovation.reminder')} ${t(`maintenance.${maintenance.maintenanceType}`)} ${dateToTableFormat(maintenance.nextMaintenance)}
        `);
      }
    });
    if (maintenances?.length) {
      dispatch(updateRemindedStatus());
    }
  }, [maintenances]);

  useEffect(() => {
    const fetchAuthDetails = async () => {
      if (!account) {
        const { tokens } = await fetchAuthSession();
        if (tokens?.idToken?.payload) {
          const { idToken: { payload } } = tokens;
          const newAccount: Account = {
            groups: payload['cognito:groups'] as string[],
            email: payload.email?.toString(),
            name: payload.name?.toString()
          };

          dispatch(updateAccount(newAccount));
        }
      }
    };

    fetchAuthDetails();
  }, [route, account]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(updateIsMobile(window.innerWidth < 1080));
    };

    dispatch(updateIsMobile(window.innerWidth < 1080));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [route]);

  if (propertiesLoading) {
    return (<Box className="page-loading-container"><Loading /></Box>);
  }

  if (isLinkURL) {
    document.documentElement.style.setProperty('--background-image', `url(${defaultBackground})`);
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="oikeudet/linkki" element={<LinkUserPage />} />
          </Routes>
        </ThemeProvider>
      </div>
    );
  }

  // Page Skeleton here prevents Property Menu rerenders
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/kirjaudu" element={<LoginPage />} />
          <Route element={isLoggedIn ? <Outlet /> : <LoginPage />}>
            <Route path="/" element={<PageSkeleton><PropertyPage /></PageSkeleton>} />
            <Route path="/huollot" element={<PageSkeleton><MaintenancePage /></PageSkeleton>} />
            <Route path="/remontit" element={<PageSkeleton><RenovationPage /></PageSkeleton>} />
            <Route path="/koneet" element={<PageSkeleton><EquipmentPage /></PageSkeleton>} />
            <Route path="/dokumentit" element={<PageSkeleton><DocumentPage /></PageSkeleton>} />
            <Route path="/rakentaminen" element={<PageSkeleton><ConstructionPage /></PageSkeleton>} />
            <Route path="/esittely" element={<PageSkeleton><BrochurePage /></PageSkeleton>} />
            <Route path="/kiinteistö/:type" element={<PropertySetupPage />} />
            <Route path="/tilaus" element={<OrderPage />} />
            <Route path="/tilaus/:type" element={<OrderPage />} />
            <Route path="/profiili" element={<PageSkeleton><ProfilePage /></PageSkeleton>} />
            <Route path="/oikeudet" element={<PageSkeleton><PermissionPage /></PageSkeleton>} />
            <Route element={isAdmin ? <Outlet /> : <Navigate to="/" />}>
              <Route path="/kayttajat" element={<PageSkeleton><UsersPage /></PageSkeleton>} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
