import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import type { RootState } from '../../store';
import { updateError } from '../../app/appSlice';
import API from '../../api/api';
import { Property, UpdateProperty } from '../../models/property';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { fetchDocuments } from '../document/documentSlice';
import deleteFromS3 from '../../aws/api/deleteFromS3';

export const fetchProperties = createAsyncThunk<Property[], undefined, { rejectValue: Error }>(
  'property/fetchProperties',
  async (_, { rejectWithValue }) => {
    try {
      const response: Property[] = await API.get('properties');
      return response;
    } catch (err: any) {
      const error = parseError(err);
      if (JSON.stringify(error.data) === '{"message":"SubExpired"}') {
        if (!window.location.pathname.startsWith('/tilaus') && window.location.pathname !== '/kiinteistö/aloitus' && window.location.pathname !== '/kiinteist%C3%B6/aloitus') {
          window.location.assign('/tilaus');
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const fetchProperty = createAsyncThunk<Property, string, { rejectValue: Error }>(
  'property/fetchProperty',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Property = await API.get(`property/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addProperty = createAsyncThunk<Property, Property, { rejectValue: Error }>(
  'property/addProperty',
  async (property, { rejectWithValue }) => {
    try {
      const response: Property = await API.post('property', property);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateProperty = createAsyncThunk<Property, UpdateProperty, { rejectValue: Error }>(
  'property/updateProperty',
  async ({ property, image }, { rejectWithValue, dispatch }) => {
    try {
      const documents = image && property.propertyId
        ? await generateDocumentIds([image], property.propertyId) : property.documents;
      const propertyWithoutId = (({ propertyId, ...o }) => o)({ ...property, documents });
      const response: Property = await API.put(`property/${property.propertyId}`, propertyWithoutId);
      dispatch(fetchDocuments(property.propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteProperty = createAsyncThunk<string, string, { rejectValue: Error }>(
  'property/deleteProperty',
  async (propertyId, { rejectWithValue }) => {
    try {
      await API.delete(`property/${propertyId}`);
      await deleteFromS3(`${propertyId}`);
      return propertyId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface PropertyState {
  properties: Property[] | undefined;
  selectedProperty: Property | undefined,
  loading: boolean;
}

const getPropertyFromLocalStorage = () => {
  const propertyInLocalStorage = localStorage.getItem('selectedProperty');
  return propertyInLocalStorage ? JSON.parse(propertyInLocalStorage) : undefined;
};

// Define the initial state using that type
const initialState: PropertyState = {
  properties: undefined,
  selectedProperty: getPropertyFromLocalStorage(),
  loading: false
};

// TODO fetch and save details from/to server
export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updateSelectedProperty: (state, action) => {
      localStorage.setItem('selectedProperty', JSON.stringify(action.payload));
      state.selectedProperty = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProperties.pending, state => {
        state.loading = true;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = action.payload;
        if (action.payload.length && !getPropertyFromLocalStorage()) {
          localStorage.setItem('selectedProperty', JSON.stringify(action.payload[action.payload.length - 1]));
          state.selectedProperty = action.payload[action.payload.length - 1];
        }
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(fetchProperty.pending, state => {
        state.loading = true;
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = state.properties || [];
        const hasProperty = state.properties.find(property => property === action.payload);
        if (!hasProperty) {
          state.properties.push(action.payload);
        }
        localStorage.setItem('selectedProperty', JSON.stringify(action.payload));
        state.selectedProperty = action.payload;
      })
      .addCase(fetchProperty.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(addProperty.pending, state => {
        state.loading = true;
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = state.properties || [];
        state.properties.push(action.payload);
        localStorage.setItem('selectedProperty', JSON.stringify(action.payload));
        state.selectedProperty = action.payload;
      })
      .addCase(addProperty.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(updateProperty.pending, state => {
        state.loading = true;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = state.properties || [];
        const updatedIndex = state.properties.findIndex(({ propertyId }) => propertyId === action.payload.propertyId);
        if (updatedIndex !== -1) {
          state.properties[updatedIndex] = action.payload;
          localStorage.setItem('selectedProperty', JSON.stringify(action.payload));
          state.selectedProperty = action.payload;
        }
        toast.success(i18next.t('property.updatedSuccessfully'));
      })
      .addCase(updateProperty.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(deleteProperty.pending, state => {
        state.loading = true;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.properties = state.properties || [];
        const removedIndex = state.properties.findIndex(({ propertyId }) => propertyId === action.payload);
        if (removedIndex !== -1) {
          const nextIndex = state.properties.length - 1 > removedIndex ? removedIndex + 1 : 0;
          localStorage.setItem('selectedProperty', JSON.stringify(action.payload));
          state.selectedProperty = state.properties[nextIndex];
          state.properties.splice(removedIndex, 1);
        }
        state.loading = false;
        toast.success(i18next.t('property.deletedSuccessfully'));
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      });
  }
});

export const { updateSelectedProperty } = propertySlice.actions;
export const selectProperties = (state: RootState) => state.property.properties;
export const selectPropertiesLoading = (state: RootState) => state.property.loading;
export const selectSelectedProperty = (state: RootState) => state.property.selectedProperty;

export default propertySlice.reducer;
